import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Alpine from "alpinejs";

/**
 * @this { { $el: HTMLDivElement } }
 */
export function initItemsSection() {
  Alpine.nextTick(() => {

    if (window.innerWidth < 1400) {
      setContainerToViewportHeight(this.$el);

      window.addEventListener('resize', () => {
        setContainerToViewportHeight(this.$el);
      });

      return;
    }

    let items = gsap.utils.toArray<HTMLDivElement>(this.$el.querySelectorAll('.items__item'));
    let spacer = this.$el.parentElement.querySelector('.items-spacer');
    let maxWidth = 0;

    const updateLayout = () => {
      // take padding into account
      maxWidth = this.$el.scrollWidth + gsap.getProperty(this.$el, 'padding-left');
      gsap.set(spacer, { minHeight: window.innerHeight - this.$el.offsetHeight });
    };

    updateLayout();
    ScrollTrigger.addEventListener('refreshInit', updateLayout);

    gsap.to(this.$el, {
      x: () => `-${ maxWidth - window.innerWidth }`,
      ease: 'none',
      scrollTrigger: {
        trigger: this.$el,
        pin: true,
        scrub: true,
        end: () => `+=${ maxWidth }`,
        invalidateOnRefresh: true
      }
    });

    // items.forEach((item, i) => {
    //   ScrollTrigger.create({
    //     trigger: item,
    //     start: () => 'top top-=' + (item.offsetLeft - window.innerWidth / 2) * (maxWidth / (maxWidth - window.innerWidth)),
    //     end: () => '+=' + item.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
    //     toggleClass: { targets: item, className: 'active' }
    //   });
    // });
  });
}

function setContainerToViewportHeight($el: HTMLDivElement) {
  $el.style.height = window.innerHeight + 'px';
}

declare global {
  interface Window {
    initItemsSection: () => void;
  }
}
window.initItemsSection = initItemsSection;