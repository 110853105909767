import 'unfocus';
import '../../scss/main.scss';
import './theme';
import './partials';
import './blocks';
import './static-pages';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// @ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';

gsap.registerPlugin(ScrollTrigger);

// @ts-ignore
if (!lightpress.insideGutenbergEditor) {
  AOS.init();
} else {
  AOS.init({ disable: () => true });
}
