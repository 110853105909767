import gsap from "gsap";

gsap.registerEffect({
  name: "counter",
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 1,
    ease: "Cubic.easeInOut",
    increment: 1,
  },
  effect: (targets, config) => {
    let animation = gsap.timeline();
    let delay = gsap.utils.distribute(config.stagger);

    targets.forEach((target, i) => {
      const endValue = target.innerHTML;
      target.innerHTML = 0;
      let tl = gsap.timeline()
        .to(target, {
          duration: config.duration,
          innerText: endValue,
          modifiers: {
            innerText: function (innerText) {
              return gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
          },
          ease: config.ease
        }, 0);

      animation.add(tl, delay(i, target, targets));
    });

    return animation;
  }
});

let container = document.querySelector(".page-intro");

if (container) {

  let entryTimeline = gsap.timeline({
    ease: 'power2.inOut',
    duration: 5,
  });

  if (window.innerWidth < 1024) {
    entryTimeline
      .counter(container.querySelector('.page-intro__progress'),
        {
          scaleX: 0,
          transformOrigin: 'left',
          duration: 5,
          ease: 'circ.inOut'
        },
        '-=5'
      )
      .from(container.querySelector('.page-intro__loader'),
        {
          scaleX: 0,
          transformOrigin: 'left',
          duration: 5,
          ease: 'circ.inOut'
        },
        '-=5'
      )
    .to(container.querySelector('.page-intro__logo'),
      {
        y: '100%',
        opacity: 0,
        duration: .75,
        ease: 'expo.out'
      },
      "end+=.25"
    )
    .to(container.querySelector('.page-intro__progress'),
      {
        y: '-100%',
        opacity: 0,
        duration: .75,
        ease: 'expo.out'
      },
      "end+=.25"
    )
    .to('.page-intro', {
        opacity: 0,
        display: 'none',
        duration: .35,
      },
      "end+=.5"
    );
  } else {
    entryTimeline
      .counter(container.querySelector('.page-intro__progress'),
        {
          scaleX: 0,
          transformOrigin: 'left',
          duration: 5,
          ease: 'circ.inOut'
        },
        '-=5'
      )
      .from(container.querySelector('.page-intro__loader'),
        {
          scaleX: 0,
          transformOrigin: 'left',
          duration: 5,
          ease: 'circ.inOut'
        },
        '-=5'
      )
      .from(container.querySelector('.page-intro__name'),
        {
          y: '100%',
          opacity: 0,
          duration: .75,
          ease: 'expo.out'
        },
        '-=4'
      )
      .to(container.querySelector('.page-intro__logo'),
        {
          y: '100%',
          opacity: 0,
          duration: .75,
          ease: 'expo.out'
        },
        "end+=.25"
      )
      .to(container.querySelector('.page-intro__name'),
        {
          y: '100%',
          opacity: 0,
          duration: .75,
          ease: 'expo.out'
        },
        "end+=.25"
      )
      .to(container.querySelector('.page-intro__progress'),
        {
          y: '-100%',
          opacity: 0,
          duration: .75,
          ease: 'expo.out'
        },
        "end+=.25"
      )
      .to('.page-intro', {
          opacity: 0,
          display: 'none',
          duration: .35,
        },
        "end+=.5"
      );
  }

}