import type {SwiperOptions} from 'swiper';
import Swiper, {Autoplay, Controller, EffectFade, Pagination, Parallax} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import gsap from 'gsap';

/**
 * @this { { $el: HTMLDivElement, slider: Swiper | null } }
 */
export function initHomeSection() {
    let portraits = this.$el.querySelector('.home__portraits > .swiper');
    let numbers = this.$el.querySelector('.home__portraits-numbers > .swiper');
    let landscapes = this.$el.querySelector('.home__landscapes > .swiper');

    setContainerToViewportHeight(this.$el);

    window.addEventListener('resize', () => {
        setContainerToViewportHeight(this.$el);
    });

    Swiper.use([Autoplay, Pagination, EffectFade, Controller, Parallax]);

    let sliderOptions: SwiperOptions = {
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        // parallax: true,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true,
        // },
    }

    let portraitsSlider = new Swiper(portraits, {
        ...sliderOptions,
        spaceBetween: 20,
        allowTouchMove: false,
        on: {
            init: playImageAnimation,
            slideChange: playImageAnimation,
        }
    });

    let numbersSlider = new Swiper(numbers, {
        ...sliderOptions,
        spaceBetween: 20,
        allowTouchMove: false,
    });

    let landscapesSlider = new Swiper(landscapes, {
        ...sliderOptions,
        spaceBetween: 20,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        controller: {
            control: [numbersSlider, portraitsSlider],
            by: 'container',
        },
        pagination: {
            el: this.$el.querySelector('.home__landscapes .swiper-pagination'),
            type: 'fraction',
            clickable: true,
            renderFraction: function (currentClass, totalClass) {
                return `<div><span class="${currentClass}">${portraitsSlider.realIndex + 1}</span>/<span class="${totalClass}">${portraitsSlider.slides.length}</span></div>`;
            },
            formatFractionCurrent: (number) => {
                return `0${number}`.slice(-2);
            },
            formatFractionTotal: (number) => {
                return `0${number}`.slice(-2);
            }
        },
        on: {
            paginationRender: (swiper, paginationEl) => {
                let bullets = '<div class="pagination-bullets">';
                for (let i = 0; i < swiper.slides.length - 2; i++) {
                    bullets += `<button class="pagination-bullet" aria-label="Slide ${i + 1} anzeigen" @click="changeSlideTo(slider, ${i})"></button>`;
                }
                bullets += '</div>';
                paginationEl.innerHTML += bullets;
            },
            paginationUpdate: (swiper, paginationEl) => {
                let bullets = paginationEl.querySelectorAll('.pagination-bullet');
                for (let i = 0; i < bullets.length; i++) {
                    bullets[i].classList.remove('active');
                }
                bullets[swiper.realIndex].classList.add('active');
            },
            init: playImageAnimation,
            slideChange: playImageAnimation,
        }
    });

    landscapesSlider.autoplay.stop();
    setTimeout(() => {
        landscapesSlider.autoplay.start();
    }, 5000);

    this.slider = landscapesSlider;
}

function playImageAnimation(swiper: Swiper) {
    let currentSlide = swiper.slides[swiper.activeIndex];
    let image = currentSlide.querySelector('img');

    if (!image) return;

    gsap.fromTo(image, {scale: 1}, {scale: 1.1, duration: 11, clear: 'all'});
}

/**
 * @this { { $el: HTMLDivElement } }
 */
export function changeSlideTo(slider: Swiper, index: number) {
    slider.slideTo(index + 1);
}

function setContainerToViewportHeight($el: HTMLDivElement) {
    if (window.innerHeight > 450 && window.innerWidth > window.innerHeight) {
        $el.parentElement.style.height = `${window.innerHeight}px`;
    } else {
        $el.parentElement.style.height = '';
    }
}

declare global {
    interface Window {
        initHomeSection: () => void;
        changeSlideTo: (slider: Swiper, index: number) => void;
    }
}
window.initHomeSection = initHomeSection;
window.changeSlideTo = changeSlideTo;