document.addEventListener('DOMContentLoaded', () => {
  if (window.innerWidth < 1400) {
    updateContentHeight();

    window.addEventListener('resize', updateContentHeight);

    return;
  }
});

function updateContentHeight() {
  let content = document.querySelector<HTMLDivElement>('.sidedrawer__content');
  content.style.height = window.innerHeight + 'px';
}