// @ts-ignore
import imagesLoaded from 'imagesloaded';
// @ts-ignore
import Masonry from 'masonry-layout';

enum ProjectArchiveView {
    LIST = 'list',
    GALLERY = 'gallery'
}

document.addEventListener('alpine:init', () => {
    // @ts-ignore
    window.Alpine.store('projectView', ProjectArchiveView.GALLERY);
    // @ts-ignore
    window.Alpine.store('projectFilter', 'Alle');
});

/**
 * @this { { $el: HTMLDivElement; masonry: Masonry } }
 */
export function initProjectGallery() {
    if (window.CSS.supports('grid-template-rows', 'masonry')) return;

    let container = this.$el.querySelector('.project-archive-gallery');

    // @ts-ignore
    window.Alpine.nextTick(() => {
        imagesLoaded(container, () => {
            this.masonry = new Masonry(container, {
                gutter: window.innerWidth < 1440 ? 40 : 85,
                percentPosition: true,
                horizontalOrder: true,
                stagger: 30,
                columnWidth: '.grid-sizer',
                // columnWidth: this.$el.querySelector('.project-archive-gallery__item:last-child'),
            });
        });
    });
}

/**
 * @this { { $el: HTMLDivElement; masonry: Masonry, filter: string } }
 */
export function onFilterUpdate() {
    if (window.CSS.supports('grid-template-rows', 'masonry')) return;

    setTimeout(() => {
        this.masonry.layout();
    }, 50);
}

declare global {
    interface Window {
        initProjectGallery: () => void;
        onFilterUpdate: () => void;
    }
}

window.initProjectGallery = initProjectGallery;
window.onFilterUpdate = onFilterUpdate;