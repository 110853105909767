import Handorgel from 'handorgel';

/**
 * @this { { $el: HTMLDivElement } }
 */
export function initAccordion() {
    let accordion = new Handorgel(this.$el.querySelector('.handorgel'), {
        multiSelectable: false,
    });
}

declare global {
    interface Window {
        initAccordion: () => void;
    }
}
window.initAccordion = initAccordion;